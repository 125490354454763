import React, {useState} from 'react';
import { Grommet, Box, Heading, Button, CheckBox, Tab, Tabs } from 'grommet';
import theme from '../../theme';
import { AddCircle } from 'grommet-icons';

const Components = (props) => {
  const [checked, setChecked] = useState(false);

  return (
    <Grommet full theme={theme} themeMode={checked ? "dark" : "light"} background="background-back">
      <Box pad={{ horizontal: 'none', vertical: 'small' }} width={{ min: 'large', max: 'xlarge' }} margin="auto">
        <Box background="light-6" direction="row" justify="between">
          <CheckBox
            {...props}
            label="Dark mode"
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
            toggle
          />
        </Box>
        <Box align="center" justify="center">
          <Heading>Buttons</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" gap="medium">
          <Button label="plain button" />
          <Button label="plain disabled" disabled />
          <Button label="plain with link" href="#" />
          <Button label="plain disabled with link" disabled href="#" />
        </Box>
        <Box align="center" justify="center" direction="row" gap="medium" margin={{top: "small"}}>
          <Button label="primary" primary />
          <Button label="primary disabled" primary disabled />
          <Button label="primary with link" primary href="#" />
          <Button label="primary disabled with link" primary disabled href="#" />
        </Box>
        <Box align="center" justify="center" direction="row" gap="medium" margin={{top: "small"}}>
          <Button label="secondary" secondary />
          <Button label="secondary disabled" secondary disabled />
          <Button label="secondary with link" secondary href="#" />
          <Button label="secondary disabled with link" secondary disabled href="#" />
        </Box>
        <Box align="center" justify="center" direction="row" gap="medium" margin={{top: "small"}}>
          <Button label="large primary" primary size="large" />
          <Button label="large secondary" secondary size="large" />
          <Button label="small primary" primary size="small" />
          <Button label="small secondary" secondary size="small" />
        </Box>

        <Box align="center" justify="center">
          <Heading margin={{ top: 'large' }}>Checkbox</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
          <CheckBox label="CheckBox" />
          <CheckBox label="disabled" disabled />
          <CheckBox label="checked" checked />
        </Box>

        <Box align="center" justify="center">
          <Heading margin={{ top: 'large' }}>Toggle</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
          <CheckBox label="Toggle" toggle />
          <CheckBox label="disabled" disabled toggle />
          <CheckBox label="checked" checked toggle />
        </Box>

        <Box align="center" justify="center">
          <Heading margin={{ top: 'large' }}>Tabs</Heading>
        </Box>
        <Box align="center" justify="center" direction="row" pad="xsmall" gap="medium">
          <Tabs justify="start">
            <Tab title="tab" />
            <Tab title="disabled" disabled plain={false} />
            <Tab title="tab" />
            <Tab title="icon tab" icon={<AddCircle />} />
          </Tabs>
        </Box>
      </Box>
    </Grommet>
  );
};

export default Components;
